import React, { useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom';
import SavedSong from "../Library/SavedSong"

export default function PlaylistSongs() {

    const [playlistSongs, setPlaylistSongs] = useState(null)
    const location = useLocation()

    useEffect(() => {
        setPlaylistSongs(location.state.data.items)
    }, [location, playlistSongs])

    console.log(location.state.data.items)




  return (
    <>
    <div className='container'>
      <div className='recom-label'> Playlist Songs </div>
      <div className='table-scroll'>
        <table className="table table-dark table-striped table-hover align-middle saved-songs">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Album</th>
              <th scope="col">Date Added</th>
              <th scope="col"> &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                </svg> </th>
            </tr>
          </thead>
        {playlistSongs ? playlistSongs.map((track, i) => {
            return <SavedSong data={track} key={track.id} id={track.track.id} index={i}/>
        }) : <></>} 
        </table>
    </div>
    </div>
    </>
  )
}
